<template>
    <!-- form modal -->

    <div
        v-if="showUpdateBankAccountModal"
        tabindex="0"
        @keydown.esc="closeModal"
  
        class="w-100 center modal pt0-l pt7"
        id="add-product-form"
        :class="showUpdateBankAccountModal && 'modal-target'"
        style="z-index: 999999"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-50-l w-90 pv2 center box-border" style="background: white" ref="bankAccountRef">
            <div class="flex justify-between pa3 box-border-bottom">
                <div class="f3 b">{{ currentBankAccount?.bankAccountNumber ? 'Update' : 'Create New' }} Bank Account</div>
                <div>
                    <router-link to="#">
                        <img src="../assets/images/Close-icon.svg" alt="" @click.prevent="closeModal" />
                    </router-link>
                </div>
            </div>

            <!--      Previously div tag-->
            <form class="entity-form" @submit.prevent="updateBankAccount" ref="formRef">
                <div class="pa3">
                    <!-- full name -->
                    <div class="flex flex-wrap items-end justify-between">
                        <div class="w-50-l w-100 pr2-l pb0-l pb3">
                            <div class="pv2 font-w1">
                                <label for="bankAccountName">Account Name<span class="required">*</span></label>
                            </div>
                            <input
                                type="text"
                                placeholder="Account Name"
                                id="bankAccountName"
                                v-model="state.bankDetails.bankAccountName"
                                required
                            />
                        </div>
                        <div class="w-50-l w-100 pl2-l">
                            <div class="pv2 font-w1">
                                <label for="bankAccountNumber">Account Number<span class="required">*</span></label>
                            </div>
                            <input
                                type="text"
                                placeholder="Account Number"
                                id="bankAccountNumber"
                                v-model="state.bankDetails.bankAccountNumber"
                                required
                            />
                        </div>
                    </div>
                    
                    <div class="flex flex-wrap justify-between items-end pt2">
                        <div class="w-50-l w-100 pr2-l pb0-l pb3">
                            <div class="pv2 font-w1">
                                <label for="bankName">Bank Name<span class="required">*</span></label>
                            </div>
                            <input
                                type="text"
                                placeholder="Bank Name"
                                id="bankName"
                                v-model="state.bankDetails.bankName"
                                required
                            />
                        </div>
                        <div class="w-50-l w-100 pl2-l">
                            <div class="pv2 font-w1">
                                <label for="sortCode">Sort Code</label>
                            </div>
                            <input
                                type="text"
                                placeholder="Sort Code"
                                id="sortCode"
                                v-model="state.bankDetails.sortCode"
                            />
                        </div>
                    </div>
                    
                    <div class="flex flex-wrap justify-between items-end pt2">
                        <div class="w-100-l w-100 pl2-l">
                            <div class="pv2 font-w1">
                                <label for="sortCode">Alias Name</label>
                            </div>
                            <input
                                type="text"
                                placeholder="Alias Name"
                                id="aliasName"
                                v-model="state.bankDetails.aliasName"
                            />
                            <small style="font-style: italic;">This will be the display name when choosing as payment option</small>
                        </div>
                    </div>

                    <div class="w-100 pt3">
                        <div class="b pb2">Default Account Number </div>
                        <div class="flex flex-row">
                            <input class="mr1" type="radio" id="yes" :value=true v-model="state.bankDetails.isDefault">
                            <label class="pr2" for="yes">Yes</label>
                            <input class="mr1" type="radio" id="no" :value=false v-model="state.bankDetails.isDefault">
                            <label class="pr2" for="no">No</label>
                        </div>
                    </div>
                   <div class="flex flex-wrap justify-between items-end  box-border-top mt3 pt1">
                        <button
                            type="submit"
                            class="btn btn-primary"
                            :disabled="state.disableSubmitButton"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </form>
                    
        </div>
    </div>
</template>

<script>
import { reactive, computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'UpdateBankAccountModal',
    props: {
        closeModal: Function,
    },

    setup(props) {
        const bankAccountRef = ref(null)
        const formRef = ref(null)
        const store = useStore()
        const showUpdateBankAccountModal = computed(() => store.state?.Settings?.showUpdateBankAccountModal)
        const currentBankAccount = computed(() => store.state?.Settings?.currentBankAccount)

        const initialState = {
            bankDetails: {
                bankAccountName: '',
                bankAccountNumber: '',
                bankName: '',
                sortCode: '',
                aliasName: '',
                isDefault: false
            },
            disableSubmitButton: false
        }
        const state = reactive(initialState)

        watch(currentBankAccount, (newValue) => {
            state.bankDetails = newValue
        })

        watch(
            () => showUpdateBankAccountModal?.value,
            () => {
                document.body.style.overflow = showUpdateBankAccountModal.value ? 'hidden' : 'auto'
            }
        )

        const closeUpdateBankAccountModal = () => {
            props.closeModal()
        }

        const updateBankAccount = () => {
            state.disableSubmitButton = true

            store.dispatch('Settings/createOrUpdateBankAccounts', state.bankDetails).then((resp) => {
                state.disableSubmitButton = false
                store.dispatch('Settings/getOrganization')
                closeUpdateBankAccountModal()
            }).catch((error) => {
                state.disableSubmitButton = false
                console.error(error)
            })
        }

        const handleOnClickOutsideModal = (e) => {
            if (bankAccountRef?.value && !bankAccountRef?.value.contains(e.target)) {
                closeUpdateBankAccountModal()
            }
        }

        return {
            state,
            updateBankAccount,
            currentBankAccount,
            showUpdateBankAccountModal,
            formRef,
            bankAccountRef,
            handleOnClickOutsideModal
        }
    },
}
</script>

<style>
    .entity-form input[type='radio'] {
        background: unset;
        border: none;
        box-shadow: none;
        padding: unset;
        width: unset;
    }
</style>
