<template>
    <app-wrapper>
        <template v-slot:child>
            <!-- Top content-->
            <InvoiceHeader :contentType="'editInvoice'" :submit="onUpdate" :id="state.invoiceId" />
            <CreateEntity :closeModal="() => toggleCreateEntity()" @on-created="entityCreated" />
            <CreateProduct :closeModal="() => toggleCreateProduct({ show: false, cat: 'PRODUCT' })" />
            <ProductVariantsModal :closeModal="() => toggleProductVariantsModal({ status: false, index: null })" @on-variant-selected="handleProdClickFromSearch" />
            <UpdateBankAccountModal :closeModal="() => toggleUpdateBankAccountModal({ show: false, bankAccount: null })" />

            <template v-if="isLoading"><Spinner /></template>

            <!-- desktop / mobile content display -->

            <!-- search, alert, and profile -->
            <!-- <div class="mobile-line">
                <div class="flex justify-between w-90 center pv3">
    
                    <div class="searchContainer">
                        <img src="imgs/search.svg" alt="">
                        <input class="searchBox pl2" type="text" name="search" placeholder="Search...">
                    </div>
    
                    <div class="flex items-center">
                        <div class="notification pr4">
                            <a href="">
                                <img src="imgs/notification.svg" alt="">
                            </a>
                        </div>
                        <div class="profile-dropdown">
                            <a href="">
                                <img src="imgs/profile.svg" alt="">
                            </a>
                        </div>
                    </div> 
                </div>
            </div> -->
            <!-- search, alert, and profile -->

            <!--Invoice Detail -->

            <form
                ref="formRef"
                @submit.prevent="onUpdate"
                class="w-90 center mini-spacing box-border"
                style="background: #fbfcfe"
            >
                <div class="flex flex-wrap justify-between w-90x center">
                    <div class="w-70-l w-100 box-border" style="background: #FBFCFE;">
                        <!-- invoice and date issued and due -->
                        <div class="flex flex-wrap justify-between ph3 pv3">
                            <div class="flex justify-between items-center w-20-l w-100">
                                <div>Invoice No</div>
                                <input
                                    v-model="state.invoice.number"
                                    class="w-100"
                                    type="text"
                                    name="invoiceNumber"
                                    :placeholder="state.invoice.number"
                                    style="border: 1px solid #E3E8EE; border-radius: 4px; "
                                />
                            </div>
                            <div class="flex flex-wrap justify-between pt4 pt0-l w-75-l w-100">
                                <div class="flex-l flex-none items-center pb3 pb0-l w-50-l w-100">
                                    <div class="pb0-l pb2">Date issued</div>
                                    <input
                                        type="date"
                                        class="input-style ml2-l ml0 w-auto-l w-100 "
                                        name="dateIssued"
                                        v-model="state.invoice.dateIssued"
                                        :placeholder="state.invoice.dateIssued"
                                    />
                                </div>

                                <div class="flex-l flex-none items-center pl3-l pl0 w-50-l w-100">
                                    <div class="pb0-l pb2">Date due</div>
                                    <input
                                        type="date"
                                        class="input-style ml2-l ml0 w-auto-l w-100"
                                        name="dateDue"
                                        v-model="state.invoice.dateDue"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <!-- invoice and upload  -->
                        <div class="flex flex-wrap justify-between ph3 pv3">
                            <div class="w-80-l w-100 pr4">
                                <div class="f4 b">Invoice</div>
                                <div class="flex flex-column pt4">
                                    <label class="pb2">Description</label>
                                    <input
                                        class="pa2 box-border"
                                        type="text"
                                        placeholder="What is this invoice about"
                                        name="description"
                                        :required="true"
                                        v-model="state.invoice.description"
                                        maxlength="50"
                                    />
                                </div>
                                <!-- disabled Recurring invoice option -->
                                <div v-if="false" class="flex flex-column pt4">
                                    <label class="pb2">Recurring</label>
                                    <input v-model="state.invoice.IsRecurring" type="checkbox" name="" id="" :value="true" />
                                </div>
                                <!--  dropdown -->
                                <div v-if="state.invoice.IsRecurring" class="flex flex-column pt4">
                                    <label class="pb2">Recurring Type</label>
                                    <select v-model="state.invoice.recurringType" class="input-style" name="invoiceType" id="">
                                        <option value="">Select</option>
                                        <option value="daily">Daily</option>
                                        <option value="weekly">Weekly</option>
                                        <option value="monthly">Monthly</option>
                                        <option value="quarterly">Quarterly</option>
                                        <option value="biannually">Biannually</option>
                                        <option value="annually">Annually</option>
                                    </select>
                                </div>
                                <!-- input -->
                                <div class="flex flex-column pt4" v-if="state.invoice.IsRecurring">
                                    <label class="pb2">Max. Number of Payments(Optional)</label>
                                    <input
                                        class="pa2 box-border"
                                        type="text"
                                        v-model="state.invoice.maxNumberOfPayments"
                                        name="maxNumberOfPayments"
                                        placeholder="5"
                                    />
                                </div>
                            </div>
                            <div class="pa4x w-20-l w-100 box-borderx mt0-l mt4x">
                                <div class="flex flex-wrap justify-between items-center mt3">
                                    <div class="w-100-l w-100 pb3 tc b f4 pb0-l">
                                        <div 
                                            class="org-img-placeholder pointer" 
                                            style="width:100px;height:100px;" 
                                            @click.prevent="handleClickUpload"
                                            @drop.prevent="handleDragImage"
                                            @dragover.prevent
                                            @dragenter.prevent
                                        >
                                            <div ref="companyLogoWrapper" v-if="orgImage">
                                                <img
                                                    ref="companyLogo"
                                                    :src="state?.image || state?.imageUrl || orgImage"
                                                    alt=""
                                                    style="object-position: center; object-fit: cover; height: 100px;"
                                                    class="rounded-circle"
                                                />
                                            </div>
                                            <span v-else>{{ orgInitials }}</span>
                                        </div>
                                        <div class="edit-profile-image-icon">
                                            <font-awesome-icon icon="fa-solid fa-camera" class="text-white" />
                                        </div>
                                        <span class="dn">
                                                <input
                                                    ref="imageElRef"
                                                    type="file"
                                                    name="imageFile"
                                                    id="imageFile"
                                                    accept="image/*"
                                                    @change.prevent="handleSelectImage"
                                                />
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- bill to , bill from -->
                        <div class="flex flex-wrap  justify-between">
                            <div v-if="!state.invoice.IsCustomerSelected" class="pa3 w-40-l w-100">
                                <div class="box-border pa3">
                                    <div class="pb3">Bill to:</div>
                                    <div class="flex justify-between pb3">
                                        <div>Customer</div>
                                        <button
                                            class="b"
                                            style="cursor: pointer; color: #fff;"
                                            @click.prevent="toggleCreateEntity(true)"
                                        >
                                            + New entity
                                        </button>
                                    </div>
                                    <div class="pa3">
                                        <div
                                            class="flex flex-column"
                                            :style="`position: ${state.isRelative ? 'relative' : 'revert'}`"
                                        >
                                            <label class="pb2 entity-name"
                                                >Customer Name
                                                <span
                                                    :class="'required'"
                                                    :style="`position: ${state.isRelative ? 'relative' : 'revert'}`"
                                                    >*</span
                                                >
                                            </label>
                                            <input
                                                autocomplete="off"
                                                class="product-input"
                                                type="text"
                                                name="entity"
                                                placeholder="Collins John"
                                                id="entity"
                                                @input="element => handleEntitySearchOnChange(element.target.value)"
                                                @focusout="
                                                    () => {
                                                        showDropDownOnSelectEntity(false)
                                                        handleIsRelative(false)
                                                    }
                                                "
                                                @focusin="
                                                    () => {
                                                        showDropDownOnSelectEntity(true)
                                                        handleIsRelative(true)
                                                    }
                                                "
                                                v-model="state.entity"
                                            />
                                            <template v-if="state.entityDropDown && state.entity?.length">
                                                <div
                                                    style="
                                                    position: absolute;
                                                    left: 0;
                                                    top: 100%;
                                                    height: auto;
                                                    width: 100%;
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;

                                                    box-shadow: 0px 1px 4px 0px #2b59ff;
                                                    background: #132c8c;
                                                    z-index: 1;
                                                    color: #fff;
                                                "
                                                    class="bg-white flex flex-column items-start"
                                                >
                                                    <div
                                                        class="flex items-center"
                                                        style="width: 100%; gap: 0.5rem; overflow: hidden; text-overflow: ellipsis"
                                                    >
                                                        <button class="mr2" @click.prevent="toggleCreateEntity(true)">
                                                            Create
                                                        </button>
                                                        <span
                                                            style="width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
                                                        >
                                                            {{ state.entity }}</span
                                                        >
                                                    </div>

                                                    <div
                                                        class="mt2"
                                                        style="width: 100%; max-height: 250px; overflow-y: scroll"
                                                        v-if="EntitySearchedList.length"
                                                    >
                                                        <ul class="w-100">
                                                            <template v-for="ent of EntitySearchedList" v-bind:key="ent._id">
                                                                <li
                                                                    class="pointer hover-bg-black-10 pa2 db"
                                                                    style="width: 100%"
                                                                    @click.prevent="handleEntityClickFromSearch(ent)"
                                                                >
                                                                    <span v-if="ent?.lastName !== undefined">
                                                                        {{ `${ent?.firstName} ${ent?.lastName}` || ent?.company }}
                                                                    </span>

                                                                    <span v-else>
                                                                        {{ `${ent?.firstName}` || ent?.company }}
                                                                    </span>
                                                                </li>
                                                            </template>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="state.invoice.IsCustomerSelected" class="pa3 w-50-l w-100">
                                <div class="box-border pa3">
                                    <div class="flex justify-between">
                                        <div class="pb3 b">Bill To:</div>
                                        <div class="b">{{ state.invoice.customer.name }}</div>
                                    </div>
                                    <div class="flex justify-between pb2">
                                        <a class="b" style="color: #132C8C; cursor: pointer" @click="toggleCustomer">{{
                                            state.updateEntity ? 'Update' : 'Edit'
                                        }}</a>
                                        <div v-if="!state.updateEntity">
                                            <div class="pb1 tr">
                                                <p>{{ state.invoice.customer.address }}</p>
                                            </div>
                                            <div class="pb1 tr">
                                                <p>{{ state.invoice.customer.email }}</p>
                                            </div>
                                            <div class="pb1 tr">
                                                <p>{{ state.invoice.customer.phone }}</p>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div
                                                class="flex flex-column pb1 tr"
                                                :style="`position: ${state.isRelative ? 'relative' : 'revert'}`"
                                            >
                                                <label class="pb2 entity-name"
                                                    >Change Customer
                                                    <span
                                                        :class="'required'"
                                                        :style="`position: ${state.isRelative ? 'relative' : 'revert'}`"
                                                        >*</span
                                                    >
                                                </label>
                                                <input
                                                    autocomplete="off"
                                                    class="product-input input-style"
                                                    type="text"
                                                    name="entity"
                                                    :placeholder="state.invoice.customer.name"
                                                    id="entity"
                                                    @input="element => handleEntitySearchOnChange(element.target.value)"
                                                    @focusout="
                                                        () => {
                                                            showDropDownOnSelectEntity(false)
                                                            handleIsRelative(false)
                                                        }
                                                    "
                                                    @focusin="
                                                        () => {
                                                            showDropDownOnSelectEntity(true)
                                                            handleIsRelative(true)
                                                        }
                                                    "
                                                    v-model="state.entity"
                                                />
                                                <template v-if="state.entityDropDown && state.entity?.length">
                                                    <div
                                                        style="
                                                        position: absolute;
                                                        left: 0;
                                                        top: 100%;
                                                        height: auto;
                                                        width: 100%;
                                                        overflow: hidden;
                                                        text-overflow: ellipsis;

                                                        box-shadow: 0px 1px 4px 0px #2b59ff;
                                                        background: #132c8c;
                                                        z-index: 1;
                                                        color: #fff;
                                                    "
                                                        class="bg-white flex flex-column items-start"
                                                    >
                                                        <div
                                                            class="flex items-center"
                                                            style="width: 100%; gap: 0.5rem; overflow: hidden; text-overflow: ellipsis"
                                                        >
                                                            <button class="mr2" @click.prevent="toggleCreateEntity(true)">
                                                                Create
                                                            </button>
                                                            <span
                                                                style="width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
                                                            >
                                                                {{ state.entity }}</span
                                                            >
                                                        </div>

                                                        <div
                                                            class="mt2"
                                                            style="width: 100%; max-height: 250px; overflow-y: scroll"
                                                            v-if="EntitySearchedList.length"
                                                        >
                                                            <ul class="w-100">
                                                                <template v-for="ent of EntitySearchedList" v-bind:key="ent._id">
                                                                    <li
                                                                        class="pointer hover-bg-black-10 pa2 db"
                                                                        style="width: 100%"
                                                                        @click.prevent="handleEntityClickFromSearch(ent)"
                                                                    >
                                                                        <span v-if="ent?.lastName !== undefined">
                                                                            {{
                                                                                `${ent?.firstName} ${ent?.lastName}` ||
                                                                                    ent?.company
                                                                            }}
                                                                        </span>

                                                                        <span v-else>
                                                                            {{ `${ent?.firstName}` || ent?.company }}
                                                                        </span>
                                                                    </li>
                                                                </template>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                            <div class="pb1 tr">
                                                <input
                                                    v-model="state.invoice.customer.address"
                                                    class="input-style"
                                                    type="text"
                                                    name=""
                                                    :placeholder="state.invoice.customer.address || 'Enter Address'"
                                                    id=""
                                                    required=""
                                                />
                                            </div>
                                            <div class="pb1 tr">
                                                <input
                                                    v-model="state.invoice.customer.email"
                                                    class="input-style"
                                                    type="text"
                                                    name=""
                                                    :placeholder="state.invoice.customer.email || 'Enter Email'"
                                                    id=""
                                                    required=""
                                                />
                                            </div>
                                            <div class="pb1 tr">
                                                <input
                                                    v-model="state.invoice.customer.phone"
                                                    class="input-style"
                                                    type="text"
                                                    name=""
                                                    :placeholder="state.invoice.customer.phone || 'Enter Phone'"
                                                    id=""
                                                    required=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="pa3 w-50-l w-100">
                                <div class="box-border pa3">
                                    <div class="flex justify-between">
                                        <div class="pb3 b">Bill From:</div>
                                        <div class="flex justify-between items-center">
                                            <div class="b">{{ state.invoice.business.name }}</div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between pb2">
                                        <a class="b" style="color: #132C8C; cursor: pointer" @click="toggleOrganaization">{{
                                            state.updateOrganaization ? 'Update' : 'Edit'
                                        }}</a>
                                        <div v-if="!state.updateOrganaization">
                                            <div class="pb1 tr">
                                                <p>{{ state.invoice.business.address }}</p>
                                            </div>
                                            <div class="pb1 tr">
                                                <p>{{ state.invoice.business.email }}</p>
                                            </div>
                                            <div class="pb1 tr">
                                                <p>{{ state.invoice.business.phone }}</p>
                                            </div>
                                            <div class="pb1 tr">
                                                <p>{{ state.invoice.business.tin }}</p>
                                            </div>
                                            <div class="pb1 tr">
                                                <p>{{ state.invoice.business.rcNumber }}</p>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div class="pb1 tr">
                                                <input
                                                    class="input-style"
                                                    v-model="state.invoice.business.address"
                                                    type="text"
                                                    name=""
                                                    placeholder="Enter Address"
                                                    id=""
                                                    required=""
                                                />
                                            </div>
                                            <div class="pb1 tr">
                                                <input
                                                    class="input-style"
                                                    v-model="state.invoice.business.email"
                                                    type="text"
                                                    name=""
                                                    :placeholder="state.invoice.business.email"
                                                    id=""
                                                    required=""
                                                />
                                            </div>
                                            <div class="pb1 tr">
                                                <input
                                                    class="input-style"
                                                    v-model="state.invoice.business.phone"
                                                    type="text"
                                                    name=""
                                                    :placeholder="state.invoice.business.phone"
                                                    id=""
                                                    required=""
                                                />
                                            </div>
                                            <div class="pb1 tr">
                                                <input
                                                    class="input-style"
                                                    v-model="state.invoice.business.tin"
                                                    type="text"
                                                    name=""
                                                    placeholder="TIN"
                                                    required=""
                                                />
                                            </div>
                                            <div class="pb1 tr">
                                                <input
                                                    class="input-style"
                                                    v-model="state.invoice.business.rcNumber"
                                                    type="text"
                                                    name=""
                                                    placeholder="RC Number"
                                                    required=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- select -->

                        <div class="flex">
                            <div class="w-40-l w-100 pa3">
                                <div class="pb2">Currency</div>
                                <div class="flex justify-between box-border pa2">
                                    <select style="width: 100%" class="select-style" v-model="state.invoice.currency">
                                        <option :value="$store.state.Settings?.currency">{{
                                            getCurrencyName($store.state.Settings?.currency)
                                        }}</option>
                                    </select>
                                </div>
                            </div>
                            <div></div>
                        </div>

                        <div>
                            <div v-for="(product, index) of state.invoice.items" v-bind:key="index">
                                <div class="flex flex-wrap items-end justify-between w-100 pa3" style="gap: 0.5rem">
                                    <div
                                        class="w-25-l w-100  flex flex-column pb3 pb0-l"
                                        :style="`position: ${state.isRelative ? 'relative' : 'revert'}`"
                                    >
                                        <label class="pb2">Items<span class="required">*</span></label>
                                        <input
                                            autofocus
                                            autocomplete="off"
                                            class="product-input"
                                            :style="
                                                !productIds[index] &&
                                                    'border: 2px solid red !important; background: #eee !important;'
                                            "
                                            type="text"
                                            :name="product.name"
                                            :id="product.name"
                                            v-model="product.name"
                                            placeholder="Enter item name"
                                            @change="element => handleProductSearchOnChange(element.target.value, index)"
                                            @input="element => handleProductSearchOnChange(element.target.value, index)"
                                            @focusout="
                                                () => {
                                                    showDropDownOnSelectProduct(index, false)
                                                    handleIsRelative(false)
                                                }
                                            "
                                            @focusin="
                                                () => {
                                                    showDropDownOnSelectProduct(index, true)
                                                    handleIsRelative(true)
                                                }
                                            "
                                            required
                                            :max="parseFloat(product.instock)"
                                        />

                                        <div v-if="product.productDropDown && product.name?.length">
                                            <div
                                                style="
                                            position: absolute;
                                            left: 0;
                                            top: 100%;
                                            height: auto;
                                            width: 100%;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            box-shadow: 0px 1px 4px 0px #2b59ff;
                                            background: #132c8c;
                                            z-index: 1;
                                            color: #fff;
                                        "
                                                class="bg-white flex flex-column items-start"
                                            >
                                                <div
                                                    class="flex items-center"
                                                    style="width: 100%; gap: 0.5rem; overflow: hidden; text-overflow: ellipsis"
                                                >
                                                    <button
                                                        class="mr2"
                                                        @click.prevent="toggleCreateProduct(true, index, product.name, 'PRODUCT')"
                                                    >
                                                        Create
                                                    </button>
                                                    <span
                                                        style="
                                                    width: 100%;
                                                    overflow: hidden;
                                                    white-space: nowrap;
                                                    text-overflow: ellipsis;
                                                "
                                                    >
                                                        {{ product.name }}</span
                                                    >
                                                </div>

                                                <div
                                                    class="mt2"
                                                    style="width: 100%; max-height: 250px; overflow-y: scroll"
                                                    v-if="productEntrySearchList?.length"
                                                >
                                                    <ul class="w-100" v-if="productEntrySearchList">
                                                        <div v-for="prod of productEntrySearchList" v-bind:key="prod._id">
                                                            <li
                                                                class="pointer hover-bg-black-10 pa2 db"
                                                                style="width: 100%"
                                                                @click.prevent="handleProdClickFromSearch(prod, index)"
                                                            >
                                                                <div>{{ prod.name }}</div> 
                                                                <div v-if="prod?.hasVariants" class="text-muted">{{ prod.variantsCount }} variants</div>
                                                            </li>
                                                        </div>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="w-20-l w-50 pr1 pr0-l  flex flex-column pb3 pb0-l">
                                        <label class="pb2">Unit price<span class="required">*</span></label>

                                        <input
                                            class="product-input"
                                            type="number"
                                            step="any"
                                            :name="product.price"
                                            v-model="product.price"
                                            placeholder="0.00"
                                            :id="product.price"
                                            @change="onChangeProductIds(index)"
                                            required
                                        />
                                    </div>

                                    <div class="w-20-l w-50 pl1 pl0-l  flex flex-column pb3 pb0-l">
                                        <label class="pb2"
                                            >Qty*<span v-if="product.type !== 'SERVICE'" class="required"
                                                >: ({{ product.instock || 0 }} In-stock)</span
                                            ></label
                                        >

                                        <input
                                            class="product-input"
                                            :class="
                                                state.invoice.type === 'sales' && product.quantity > product.instock ? 'red' : ''
                                            "
                                            :style="
                                                (state.invoice.type === 'sales' && product.instock <= 10) ||
                                                (state.invoice.type === 'sales' && product.quantity < product.instock) // eslint-disable-next-line max-len
                                                    ? 'border: 1px solid red !important; background: #eee !important; outline: 1px solid red !important; background: #eee !important;'
                                                    : ''
                                            "
                                            type="number"
                                            step="any"
                                            :name="product.quantity"
                                            v-model="product.quantity"
                                            placeholder="0"
                                            :id="product.quantity"
                                            required
                                        />
                                    </div>

                                    <div class="w-20-l w-80  flex flex-column">
                                        <label class="pb2">Total</label>

                                        <input
                                            class="product-input"
                                            @change="input => (state.amount = parseFloat(input.target.value))"
                                            type="number"
                                            step="any"
                                            name="totalAmountValue"
                                            :value="product.total"
                                            id="totalAmountValue"
                                            required
                                            :disabled="true"
                                        />
                                    </div>

                                    <div class="pt4" @click.prevent="deleteProduct(index)">
                                        <img :src="require('@/assets/images/delete.svg')" alt="Delete" />
                                    </div>
                                    <div class="w-100-l w-100 pl1 pl0-l pb3 pb0-l">
                                        <a href="" @click.prevent="product.showNote = !product?.showNote || !true">Add Note</a>
                                        &nbsp;
                                        <a href="" @click.prevent="product.showDiscount = !product.showDiscount || !true"
                                            >Add Discount</a
                                        >
                                        <div v-show="product.showDiscount" class="pb1">
                                            <input
                                                type="number"
                                                v-model="product.discount"
                                                class="w-90 product-input discountInput"
                                                placeholder="Discount"
                                            />
                                            <select v-model="product.discountType" class="w-10 product-input optionInput">
                                                <option value="percentage">%</option>
                                                <option :value="state.invoice.currency">{{
                                                    getCurrencyName(state.invoice.currency)
                                                }}</option>
                                            </select>
                                        </div>
                                        <div>
                                            <textarea
                                                v-if="product.showNote"
                                                v-model="product.notes"
                                                class="w-100 product-input"
                                                placeholder="Enter item note"
                                                rows="1"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tr b pa3">
                                <div class="pb3 box-border-bottom">
                                    <a href="" style="color: #132c8c" @click.prevent="addMoreItems"> + New line </a>
                                </div>
                            </div>
                        </div>

                        <div class="flex pa3">
                            <div class="w-100">
                                <label>Tags</label>
                                <vue-tags-input class="mt2 w-100"
                                    v-model="state.tag"
                                    :tags="state.invoice.tags"
                                    :validation="state.validation"
                                    :autocomplete-items="state.myTags"
                                    :max-tags="5"
                                    placeholder="Add or select tag"
                                    @tags-changed="newTags => state.invoice.tags = newTags"
                                />
                            </div>
                        </div>

                        <!-- terms & note & sub total -->
                        <div class="flex flex-wrap justify-between items-center pb3">
                            <div class="flex flex-column pa3 w-50-l w-100">
                                <label class="pb3" for="">Terms & Note</label>
                                <textarea
                                    rows="7"
                                    class="w-100"
                                    placeholder="Terms & note"
                                    v-model="state.invoice.notes"
                                    id="terms"
                                    @change="onChangeProductIds"
                                ></textarea>
                            </div>
                            <div class="w-50-l w-100 pa3">
                                <div class="flex justify-between box-border-bottom pb2">
                                    <div>Subtotal</div>
                                    <div>{{ formatAmount(state.invoice.subtotal, $store.state.Settings?.currency) }}</div>
                                </div>
                                <div class="flex justify-between pv2">
                                    <div></div>
                                    <router-link to="#" @click.prevent="toggleExtras('hasDiscount')" style="color: #132C8C;">
                                        {{ state.invoice.hasDiscount ? ' - Remove Discount' : '+ Add Discount' }}
                                    </router-link>
                                    <!-- input -->
                                    <div v-if="state.invoice.hasDiscount" class="flex flex-row">
                                        <!-- width 50  -->
                                        <select
                                            style="
                                            background-color: white;
                                            color: #634f4f;
                                            border: 1px solid rgb(118, 118, 118);
                                            border-radius: 0;
                                            width: 40%;
                                            margin-right: 10px;
                                        "
                                            name="discountType"
                                            id="discountType"
                                            v-model="state.invoice.discountType"
                                        >
                                            <option value="fixed">Fixed</option>
                                            <option value="percentage">Percentage %</option>
                                        </select>

                                        <input
                                            style="background-color: white;
                                            color: #634f4f;
                                            border: 1px solid rgb(118, 118, 118);
                                            border-radius: 0;
                                            width: 50%;"
                                            type="number"
                                            step="any"
                                            name="discount"
                                            v-model="state.invoice.discount"
                                            placeholder="0.00"
                                            id="discount"
                                            @change="onChangeProductIds"
                                        />
                                    </div>
                                </div>
                                <div class="flex justify-between pv2">
                                    <div></div>
                                    <router-link to="#" @click.prevent="toggleExtras('hasCommission')" style="color: #132C8C;">
                                        {{ state.invoice.hasCommission ? ' - Remove Commission' : '+ Add Commission' }}
                                    </router-link>
                                    <!-- input -->
                                    <div v-if="state.invoice.hasCommission" class="flex flex-row">
                                        <!-- width 50  -->
                                        <select
                                            style="
                                            background-color: white;
                                            color: #634f4f;
                                            border: 1px solid rgb(118, 118, 118);
                                            border-radius: 0;
                                            width: 40%;
                                            margin-right: 10px;
                                        "
                                            name="commissionType"
                                            id="commissionType"
                                            v-model="state.invoice.commissionType"
                                        >
                                            <option value="fixed">Fixed</option>
                                            <option value="percentage">Percentage %</option>
                                        </select>

                                        <input
                                            style="
                                            background-color: white;
                                            color: #634f4f;
                                            border: 1px solid rgb(118, 118, 118);
                                            border-radius: 0;
                                            width: 50%;
                                        "
                                            type="number"
                                            step="any"
                                            name="commission"
                                            v-model="state.invoice.commission"
                                            placeholder="0.00"
                                            id="commission"
                                            @change="onChangeProductIds"
                                        />
                                    </div>
                                </div>
                                <div class="flex justify-between pv2">
                                    <div></div>
                                    <router-link to="#" @click.prevent="toggleExtras('hasShipping')" style="color: #132C8C;">
                                        {{ state.invoice.hasShipping ? ' - Remove Shipping' : '+ Add Shipping' }}
                                    </router-link>
                                    <!-- input -->
                                    <div v-if="state.invoice.hasShipping" class="flex flex-row">
                                        <!-- width 50  -->
                                        <select
                                            style="
                                            background-color: white;
                                            color: #634f4f;
                                            border: 1px solid rgb(118, 118, 118);
                                            border-radius: 0;
                                            width: 40%;
                                            margin-right: 10px;
                                        "
                                            name="shippingType"
                                            id="shippingType"
                                            v-model="state.invoice.shippingType"
                                        >
                                            <option value="fixed">Fixed</option>
                                            <option value="percent">Percentage %</option>
                                        </select>

                                        <input
                                            style="
                                            background-color: white;
                                            color: #634f4f;
                                            border: 1px solid rgb(118, 118, 118);
                                            border-radius: 0;
                                            width: 50%;
                                        "
                                            type="number"
                                            step="any"
                                            name="shipping"
                                            v-model="state.invoice.shipping"
                                            placeholder="0.00"
                                            id="shipping"
                                            @change="onChangeProductIds"
                                        />
                                    </div>
                                </div>
                                <div class="flex justify-between pv2">
                                    <div></div>
                                    <router-link to="#" @click.prevent="toggleExtras('hasTax')" style="color: #132C8C;">
                                        {{ state.invoice.hasTax ? ' - Remove Tax' : '+ Add Tax' }}
                                    </router-link>
                                    <!-- input -->
                                    <div v-if="state.invoice.hasTax" class="flex flex-row">
                                        <!-- width 50  -->
                                        <select
                                            style="
                                            background-color: white;
                                            color: #634f4f;
                                            border: 1px solid rgb(118, 118, 118);
                                            border-radius: 0;
                                            width: 40%;
                                            margin-right: 10px;
                                        "
                                            name="taxType"
                                            id="taxType"
                                            v-model="state.invoice.taxType"
                                        >
                                            <option value="fixed">Fixed</option>
                                            <option value="percentage">Percentage %</option>
                                        </select>

                                        <input
                                            style="
                                            background-color: white;
                                            color: #634f4f;
                                            border: 1px solid rgb(118, 118, 118);
                                            border-radius: 0;
                                            width: 50%;
                                        "
                                            type="number"
                                            step="any"
                                            name="tax"
                                            v-model="state.invoice.tax"
                                            placeholder="0.00"
                                            id="shipping"
                                            @change="onChangeProductIds"
                                        />
                                    </div>
                                </div>

                                <div class="flex justify-between pv2">
                                    <div></div>
                                    <router-link to="#" @click.prevent="toggleExtras('hasOthersCharges')" style="color: #132C8C;">
                                        {{ state.invoice.hasOthersCharges ? ' - Remove Others' : '+ Add Others' }}
                                    </router-link>
                                    <!-- input -->
                                    <div v-if="state.invoice.hasOthersCharges" class="flex flex-row">
                                        <!-- others input  -->
                                        <input
                                            style="
                                            background-color: white;
                                            color: #634f4f;
                                            border: 1px solid rgb(118, 118, 118);
                                            border-radius: 0;
                                            width: 50%;
                                        "
                                            type="text"
                                            step="any"
                                            name="others"
                                            v-model="state.invoice.others"
                                            placeholder="Misc. Charges"
                                            id="others"
                                            @change="onChangeProductIds"
                                        />

                                        <input
                                            style="
                                            background-color: white;
                                            color: #634f4f;
                                            border: 1px solid rgb(118, 118, 118);
                                            border-radius: 0;
                                            width: 50%;
                                        "
                                            type="number"
                                            step="any"
                                            name="othersCharges"
                                            v-model="state.invoice.othersCharges"
                                            placeholder="0.00"
                                            id="othersCharges"
                                            @change="onChangeProductIds"
                                        />
                                    </div>
                                </div>
                                <div class="flex justify-between pv2" style="border-top:1px solid #e3e8ee;">
                                    <div>Total</div>
                                    <div class="f4 b">
                                        {{ formatAmount(state.invoice.totalAmount, $store.state.Settings?.currency) }}
                                    </div>
                                </div>
                                <div v-if="$route.fullPath.startsWith('/invoice')" class="flex justify-between pt2">
                                    <div></div>
                                    <div class=" flex items-center ">
                                        <label class="fw5 pr3">Amount Paid</label>
                                        <CurrencyInput
                                            class="box-border pa2 product-inputx"
                                            name="initial_deposit"
                                            v-model="state.invoice.initial_deposit"
                                            :options="numberFormat"
                                            placeholder="0.00"
                                            id="initial_deposit"
                                            :disabled="state.invoice.totalAmount <= 0"
                                        />
                                    </div>
                                </div>
                                <div v-if="$route.fullPath.startsWith('/invoice')" class="flex justify-between pt3">
                                    <div></div>
                                    <div>
                                        <div class="flex items-center">
                                            <div class="pr3">Balance Due</div>
                                            <div class="f5 b" style="color: #132C8C;">{{ formatAmount(state.invoice.totalAmount - state.invoice.initial_deposit, $store.state.Settings?.currency) }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="state.invoice.initial_deposit > 0" class="flex items-center justify-end pt3">
                                    <div class="flex items-center">
                                        <div class="pr2">Mode of Payment</div>
                                        <select v-model="state.invoice.glAccounts[0].glAccount" name="stats" id="stats" style="padding: 5px;" :disabled="state.invoice.totalAmount <= 0">
                                            <option value="Cash">Cash</option>
                                            <option v-for="(account, index) in bankAccounts" :key="index" 
                                            :value="account?.aliasName || account?.bankName"
                                            >{{ account?.aliasName || account?.bankName }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div v-if="state.invoice.initial_deposit > 0" class="flex items-center justify-end pt3">
                                    <div class="fw5 pr2" style="color: #132C8C;"> 
                                        Date: <input
                                            type="date"
                                            class="input-style ml2-l ml0 w-auto-l w-100 "
                                            name="date"
                                            v-model="state.invoice.date"
                                            :placeholder="state.invoice.date"
                                            :disabled="state.invoice.totalAmount <= 0"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-30-l w-100 Accepted-payment mt4 mt0-l">
                        <div class="box-border">
                            <div class="flex justify-between items-center box-border-bottom pa3">
                                <div>Accepted Payments</div>
                                <div><img src="imgs/arrow-down.svg" alt="" /></div>
                            </div>
                            <!-- get bankDetails from list -->
                            <div class="flex justify-end items-end box-border-bottom pa3">
                                <a
                                    href="#"
                                    @click.prevent="toggleUpdateBankAccountModal({ show: true, bankAccount: bankDetails })"
                                >
                                    + Add Bank Account
                                </a>
                            </div>
                            <div v-if="state.acceptedPayments.length > 0" class="pa3">
                                <div v-for="bankDetail of state.acceptedPayments" v-bind:key="bankDetail">
                                    <bank-detail :bank="bankDetail" @update-bank-details="addRemovePaymentPlan" />
                                </div>
                            </div>
                            <!-- <div class="flex justify-between items-center box-border-bottom pb3 pa3 pt0">
                                <div class="pr5">Paystack</div>
                                <a v-if="state.invoice.paystackSlug === ''" @click.prevent="generatePaystackLink" style="color:#132C8C;cursor:pointer"
                                    >Insert</a>
                                <a v-if="state.invoice.paystackSlug !== ''" href="" @click.prevent="removePaystackLink" style="color:red;cursor:pointer;overflow:hidden">Remove</a>
                            </div> -->
                        </div>

                        <div class="box-border mt4" style="background: #FBFCFE;">
                            <div class="flex pa3 justify-between items-center box-border-bottom">
                                <div class="w-40x b">Reminder</div>
                            </div>
                            <div class="flex justify-between items-center box-border-bottom pb3 pa3 pt3">
                                <div class="pr5">Invoice reminder <font-awesome-icon v-tippy="'If checked, we will send automatic email reminders 30 days, 7 days before and due date of Invoice and if still unpaid, send reminder every week after'" icon="fa-solid fa-exclamation-circle" class="text-muted" /></div>
                                <div>
                                    <toggle-switch :checked="state.invoice.reminder" @setCheckValue="setCheckValue" />
                                </div>
                            </div>
                        </div>

                        <div class="box-border mt4" style="background: #FBFCFE;">
                            <div class="flex pa3 justify-between items-center box-border-bottom">
                                <div class="w-40x b">Shareable Link</div>
                            </div>
                            <div class="pa3">
                                <div class="share-link-container box-border pa3"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="previewandsend">
                    <button v-if="$route.fullPath.startsWith('/invoice')" class="btn2" @click.prevent="onUpdate('draft')">
                        Save Invoice as Draft
                    </button>
                    <button class="ml2" @click.prevent="onUpdate('send')">
                        Preview
                    </button>
                </div>
            </form>
        </template>
    </app-wrapper>
</template>

<script>
import { onMounted, computed, ref, watch, reactive, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { directive } from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import { formatAmount, formatQuantity, formatDateFilter, getCurrencyName } from '@/utils/lib'
import AppWrapper from '@/layout/AppWrapper'
import InvoiceHeader from '@/views/Invoice/widgets/InvoiceHeader'
import InvoiceStatus from '@/views/Invoice/widgets/InvoiceStatus'
import BankDetail from '@/views/Invoice/widgets/BankDetail'
import CreateEntity from '@/components/CreateEntity'
import CreateProduct from '@/components/CreateProduct'
import ProductVariantsModal from '@/components/ProductVariantsModal'
import UpdateBankAccountModal from '@/components/UpdateBankAccountModal'
import CurrencyInput from '@/components/CurrencyInput'
import ToggleSwitch from '@/components/ToggleSwitch'
import VueTagsInput from "@sipec/vue3-tags-input"

import Spinner from '@/components/Spinner'
// import Filter from '@/components/Filter'

export default {
    name: 'InvoiceEdit',
    components: {
        AppWrapper,
        BankDetail,
        CreateEntity,
        CreateProduct,
        InvoiceHeader,
        InvoiceStatus,
        Spinner,
        UpdateBankAccountModal,
        CurrencyInput,
        ToggleSwitch,
        ProductVariantsModal,
        VueTagsInput,
    },
    directives: {
      tippy: directive,
    },

    data() {
        return {
            numberFormat: {
                currency: 'USD',
                currencyDisplay: 'hidden',
                hideCurrencySymbolOnFocus: true,
                hideGroupingSeparatorOnFocus: false,
                hideNegligibleDecimalDigitsOnFocus: true,
                autoDecimalDigits: false,
                useGrouping: true,
                accountingSign: true
            }
        }
    },

    setup() {
        const router = useRouter()
        const store = useStore()
        const formRef = ref(null)
        const imageElRef = ref(null)

        const orgData = computed(() => store.state?.Settings?.organization)
        const productIds = computed(() => store.state.Inventory?.productIds)
        const productEntrySearchList = computed(() => store.state.Inventory?.productEntrySearchList)
        const tempInStock = computed(() => store?.state?.Inventory?.tempInStock)
        const productPrices = computed(() => store.state.Inventory?.productPrices)
        const EntitySearchedList = computed(() => store.state.Entity?.entityListFromSearch)
        const incomingEntityId = computed(() => store.state.Entity?.incomingEntityId)
        const orgInitials = computed(() => store?.state?.Auth?.orgInitials)
        const orgImage = computed(() => store?.state?.Auth?.orgImage)
        const isLoading = computed(() => store.state.Invoice.isLoading)
        const invoiceNumber = computed(() => store.state.Invoice.invoiceNumber)
        const invoice = computed(() => store?.state.Invoice.invoice)
        const accountTaxDefault = computed(() => store.state?.Settings?.accountTaxDefault)
        const currencyDefault = computed(() => store.state?.Settings?.currency)
        const bankAccounts = computed(() => store.state?.Settings?.bankAccounts)
        const allowZeroStockLevel = computed(() => store.state?.Settings?.allowZeroStockLevel)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)

        const route = useRoute()

        onMounted(() => {
            const storeAction = route.fullPath.startsWith('/quotations')
                ? 'Invoice/getQuotation'
                : 'Invoice/getInvoice'
                
            store.dispatch(storeAction, route.params.id)

            store.dispatch('Settings/getOrganization')
            store.dispatch('Tag/getTags').then(data => {
                if (data.status) {
                    state.myTags = data?.data || []
                }
            })
            store.dispatch('Settings/getAccountSettings').then(data => {
                if (data.status) {
                    state.allowZeroStockLevel = data?.data?.allowZeroStockLevel || false
                }
            })
        })

        watch(
            () => productPrices.value,
            (curr, prev) => {
                if (curr !== prev) {
                    // console.log(curr)
                }
            }
        )

        watch(
            () => currencyDefault?.value,
            (curr, prev) => {
                if (curr !== prev) {
                    state.invoice.currency = curr
                }
            }
        )

        // initialize form data
        const initialState = {
            invoice: {
                number: '',
                dateIssued: formatDateFilter(Date.now()),
                dateDue: '',
                description: '',
                paystackSlug: '',
                paystackLink: '',
                reminder: false,
                status: [],
                customer: {
                    id: '',
                    name: '',
                    email: '',
                    phone: '',
                    address: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: '',
                    vat_number: '',
                    tax_number: '',
                    website: ''
                },
                items: [
                    {
                        name: '',
                        product: '',
                        price: 0,
                        quantity: 1,
                        instock: 0,
                        discount: 0,
                        discountType: 'percentage',
                        showDiscount: false,
                        notes: '',
                        showNote: false,
                        total: 0,
                        productDropDown: false,
                        sku: '',
                        id: '',
                        type: ''
                    }
                ],
                notes: '',
                subtotal: 0,
                discount: 0,
                tax: 0,
                totalAmount: 0,
                totalAfterDiscount: 0,
                currency: 'NGN',
                bankDetails: [],
                shipping: 0,
                commission: 0,
                othersCharges: 0,
                others: '',
                hasTax: false,
                hasDiscount: false,
                hasShipping: false,
                hasCommission: false,
                hasOthersCharges: false,
                IsRecurring: false,
                recurringType: '',
                maxNumberOfPayments: '',
                business: {
                    name: '',
                    email: '',
                    phone: '',
                    address: '',
                    tin: '',
                    rcNumber: '',
                },
                discountType: '',
                commissionType: '',
                taxType: '',
                shippingType: '',
                IsCustomerSelected: false,
                date: formatDateFilter(Date.now()),
                initial_deposit: 0,
                glAccounts: [{
                    glAccount: 'Cash',
                    glAmount: 0
                }],
                template: 'letterhead',
                color: '#132C8C',
                font: 'Arial',
                tags: [],
            },
            isLoading: false,
            isSaving: false,
            isSaved: false,
            isError: false,
            errorMessage: '',

            acceptedPayments: [],
            isLoadingAcceptedPayments: false,
            entity: '',
            entityName: '',
            entityDropDown: false,
            isRelative: false,
            currProdIndex: 0,
            entityId: '',
            discountType: '',
            commissionType: '',
            taxType: '',
            shippingType: '',
            invoiceId: route.params.id,
            updateEntity: false,
            updateOrganaization: false,
            imageFile: '',
            image: '',
            imageUrl: '',
            tag: '',
            myTags: [],
            validation: [
                {
                    classes: 'min-length',
                    rule: tag => tag.text.length < 3,
                },
                {
                    classes: 'no-numbers',
                    rule: /^([^0-9]*)$/,
                },
                {
                    classes: 'avoid-item',
                    rule: /^(?!Cannot).*$/,
                    disableAdd: true,
                },
                {
                    classes: 'no-braces',
                    rule: ({ text }) => text.indexOf('{') !== -1 || text.indexOf('}') !== -1,
                }
            ],
        }

        const state = reactive(initialState)

        // console.log(state)

        // onmount get invoice number
        watch(
            () => [invoiceNumber.value, orgData.value],
            (curr, prev) => {
                if (curr !== prev) {
                    if (curr[0] !== 0 && curr[1].name) {
                        // console.log(curr, 'invoice number')
                        state.invoice.number = curr[0]
                        state.invoice.business.name = curr[1].name
                        // get first letter
                        // split with space

                        const shortName = curr[1].name.split(' ')
                        // console.log('short', curr)
                        // get first letter
                        const firstLetter = shortName[0]?.charAt(0) + shortName[1]?.charAt(0)

                        // pad with four zeros
                        const paddedNumber = ('0000' + curr[0]).slice(-4)
                        state.invoice.number = firstLetter.toUpperCase() + paddedNumber
                        // console.log(state.invoice.number, 'invoice number')
                    }
                }
            }
        )

        let newInStock
        watch(
            () => tempInStock.value,
            (curr, prev) => {
                if (curr !== prev) {
                    newInStock = parseFloat(curr || 0)
                }
            }
        )

        watch(
            () => [
                state.invoice.subtotal,
                state.invoice.discount,
                state.invoice.tax,
                state.invoice.shipping,
                state.invoice.commission,
                state.invoice.othersCharges,
                state.invoice.shippingType,
                state.invoice.commissionType,
                state.invoice.taxType,
                state.invoice.discountType
            ],
            (curr, prev) => {
                // console.log(curr)
                let SUBTOTAL = parseFloat(curr[0] || 0)
                let DISCOUNT = parseFloat(curr[1] || 0)
                let TAX = parseFloat(curr[2] || 0)
                let SHIPPING = parseFloat(curr[3] || 0)
                let COMMISSION = parseFloat(curr[4] || 0)
                let OTHERS_CHARGES = parseFloat(curr[5] || 0)

                const SHIPPING_TYPE = curr[6]
                const COMMISION_TYPE = curr[7]
                const TAX_TYPE = curr[8]
                const DISCOUNT_TYPE = curr[9]
                let discountedAmount = SUBTOTAL - DISCOUNT
                if (DISCOUNT_TYPE === 'percentage') {
                    discountedAmount = SUBTOTAL - (DISCOUNT / 100) * SUBTOTAL
                    // console.log('discount');
                    // discountedAmount = curr[0] -  (curr[0] * (curr[1] / 100))
                    // state.invoice.totalAmount = (discountedAmount) + curr[3] + curr[2] + curr[4] + curr[5]
                }
                if (SHIPPING_TYPE === 'percentage') {
                    SHIPPING = (SHIPPING / 100) * discountedAmount
                    // console.log('shipping');
                    // state.invoice.totalAmount = (discountedAmount) + (curr[3] * (discountedAmount)) / 100 + curr[2] + curr[4] + curr[5]
                }
                if (COMMISION_TYPE === 'percentage') {
                    COMMISSION = (COMMISSION / 100) * discountedAmount
                    // state.invoice.totalAmount = (discountedAmount) + (curr[4] * (discountedAmount)) / 100 + curr[2] + curr[3] + curr[5]
                }
                if (TAX_TYPE === 'percentage') {
                    TAX = (TAX / 100) * discountedAmount
                    // state.invoice.totalAmount = (discountedAmount) + (curr[2] * (discountedAmount)) / 100 + curr[3] + curr[4] + curr[5]
                }

                // else {
                //     state.invoice.totalAmount = (discountedAmount) + curr[2] + curr[3] + curr[4] + curr[5]
                // }

                state.invoice.totalAmount = discountedAmount + SHIPPING + TAX + COMMISSION + OTHERS_CHARGES
            }
        )

        watch(orgData, (newVal, oldVal) => {
            if (newVal) {
                if (newVal.bankDetails) {
                    if (newVal.bankDetails.length === 0) {
                        state.acceptedPayments = []
                    } else {
                        state.acceptedPayments = newVal.bankDetails
                        state.invoice.business.name = newVal.name
                        state.invoice.business.address = newVal.address
                        state.invoice.business.email = newVal.orgEmail
                        state.invoice.business.phone = newVal.phoneNumber
                        state.invoice.business.tin = newVal.tin
                        state.invoice.business.rcNumber = newVal?.rcNumber || ''
                    }
                }
            }
        })

        const bankDetails = {
            bankAccountName: '',
            bankAccountNumber: '',
            bankName: '',
            sortCode: '',
            isDefault: false
        }

        const toggleUpdateBankAccountModal = (bankAccount = null) => {
            store.dispatch('Settings/showUpdateBankAccountModal', bankAccount)
        }

        const toggleCreateEntity = payload => {
            store.dispatch('Entity/toggleCreateEntity', payload)
            if (payload && state.invoice.customer.name) store.dispatch('Entity/setEntityName', state.entity)
        }

        let timeOut = null
        const handleProductSearchOnChange = (query, newIndex) => {
            onChangeProductIds(newIndex, 'reset')
            state.invoice.items[newIndex].name = query

            clearTimeout(timeOut)

            onChangeCurrProdIndex(newIndex)
            if (query.trim().length > 0) {
                timeOut = setTimeout(() => {
                    store.dispatch('Inventory/getProductEntryBySearch', { query, type: 'BOTH' })
                }, 300)
            }
        }

        const calculateItemTotal = index => {
            if (state?.invoice?.items && state.invoice.items.length > index) {
            const cost = state.invoice.items[index].price * state.invoice.items[index].quantity
            state.invoice.items[index].total = cost - (state.invoice.items[index].discount / 100) * cost
        }}

        const showDropDownOnSelectProduct = (index, dropDownState = false, assetPurchase) => {
            if (!dropDownState) {
                // return setTimeout(() => {
                setTimeout(() => {
                    if (state.invoice.items?.[index]?.productDropDown) {
                        state.invoice.items[index].productDropDown = dropDownState
                    }
                }, 300)
            } else {
                state.invoice.items[index].productDropDown = dropDownState
            }
            calculateItemTotal(index)
        }

        const handleIsRelative = (value = false) => {
            setTimeout(() => {
                state.isRelative = value
            }, 300)
        }

        const toggleCreateProduct = (payload, index, data) => {
            store.dispatch('Inventory/showCreateProduct', { show: payload, cat: 'PRODUCT' })
            if (payload && data) {
                store.dispatch('Inventory/showIncomingProductName', data)
            }
        }

        const toggleProductVariantsModal = (payload) => {
            store.dispatch('Inventory/toggleProductVariantsModal', payload)
        }

        const handleProdClickFromSearch = (value, index, assetPurchase) => {
            if (value.hasVariants) {
                store.dispatch('Inventory/getProductVariants', value._id)
                store.dispatch('Inventory/toggleProductVariantsModal', { status: true, index })
                return 
            }
            const payload = { value, index }
            const purchaseQty = parseFloat(value?.purchase_qty || 0)
            const salesQty = parseFloat(value?.sales_qty || 0)
            const inStock = purchaseQty - salesQty
            const mainInStockValue = parseFloat(value?.qty_in_stock || 0)
            if (state?.invoice?.items && state.invoice.items.length > index) {
            state.invoice.items[index].instock = newInStock || mainInStockValue || inStock

            newInStock = 0
            // state.products[index].instock = mainInStockValue || inStock
            state.invoice.items[index].name = value.name
            state.invoice.items[index].type = value.type

            state.invoice.items[index].sku = value.sku
            state.invoice.items[index].id = value._id
            state.invoice.items[index].price = value.sellingPrice ?? value.unitPrice
            }
            // console.log(value, 'value')
            // store.dispatch('Inventory/setProductIdFromExistingProducts', value._id).then((resp) => {
            onChangeProductIds(index)
            store.dispatch('Inventory/setProductIdFromExistingProducts', payload).then(resp => {
                // if (resp) onChangeProductIds(index)
            })

            showDropDownOnSelectProduct(index)

            // if (state.type === 'sales') {
            //     if (state.products[index].quantity < 100) {
            //         store.dispatch('Alert/setAlert', {
            //             message: `The selected product "(${value.name})" is low in stock, you need to purchase more`,
            //             status: false,
            //         })
            //     }
            // }
        }

        const onChangeProductIds = (index, value) => {
            // console.log('value', productIds.value)

            if (state.invoice.items[index]) {
                // state.products[index].product = productIds.value[index]
                if (value) {
                    store.dispatch('Inventory/removeProductId', index)
                } else {
                    state.invoice.items[index].product = productIds.value[index]
                    // state.products[index].product = productIds.value[index]
                    // console.log(state.products[index].product, productIds.value[index])

                    if (!state.invoice.items?.[index]?.price) {
                        // eslint-disable-next-line
                        state.invoice.items[index].price =
                            state.invoice.type === 'sales'
                                ? productPrices.value[index]?.sellingPrice || productPrices.value[index]?.unitPrice || 0
                                : productPrices.value[index]?.buyingPrice || 0
                    }

                    // eslint-disable-next-line
                    watch(
                        () => state.invoice.items?.[index]?.product,
                        // eslint-disable-next-line
                        (prev, next) => {
                            // eslint-disable-next-line
                            if (state.invoice.items?.[index]?.price) {
                                calculateItemTotal(index)
                                // eslint-disable-next-line
                                state.invoice.items[index].price =
                                    state.invoice.type === 'sales'
                                        ? productPrices.value[index]?.sellingPrice || productPrices.value[index]?.unitPrice || 0
                                        : productPrices.value[index]?.buyingPrice || 0
                            }
                        }
                    )
                }
            }
        }

        const onChangeCurrProdIndex = newIndex => {
            state.currProdIndex = newIndex
        }

        const totalAmountValue = computed(() => {
            return state.invoice.items.reduce((prevVal, currValue) => {
                const cost = currValue.price * currValue.quantity
                const discountTotal = currValue?.discountType === 'percentage' ? (currValue.discount / 100) * cost : currValue.discount
                currValue.total = cost - discountTotal
                const discountedCost = cost - discountTotal
                prevVal += discountedCost

                return prevVal
            }, 0)
        })

        watch(invoice, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                state.invoice = newValue
                state.invoice.status = []
            }
        })

        watch(
            () => totalAmountValue.value,
            (curr, prev) => {
                if (curr !== prev) {
                    state.invoice.subtotal = curr
                }
                // eslint-disable-next-line
                // state.invoice.totalAmount = next
            }
        )

        const handleEntitySearchOnChange = query => {
            state.entity = query

            clearTimeout(timeOut)

            if (query.trim().length) {
                timeOut = setTimeout(() => {
                    store.dispatch('Entity/getEntityEntryBySearch', { query })
                }, 300)
            }
        }

        const showDropDownOnSelectEntity = (dropDownState = false) => {
            if (!dropDownState) {
                return setTimeout(() => {
                    state.entityDropDown = dropDownState
                }, 300)
            }

            state.entityDropDown = dropDownState
        }

        const handleEntityClickFromSearch = value => {
            // console.log(value, 'value')

            if (value?.lastName || value?.lastName === undefined) {
                state.entity = `${value?.firstName} ${value?.lastName || ''}` || value?.company
            } else {
                state.entity = `${value?.firstName}` || value?.company
            }

            state.entityName = value._id

            // update invoice customer
            if (value._id) {
                state.invoice.customer.id = value._id
                state.invoice.customer.name = value.firstName
                state.invoice.customer.address = value.address1
                state.invoice.customer.city = value.city
                state.invoice.customer.state = value.state
                state.invoice.customer.zip = value.zip
                state.invoice.customer.country = value.country
                state.invoice.customer.phone = value.phone
                state.invoice.customer.email = value.email

                state.IsCustomerSelected = true
            }

            store.dispatch('Entity/setEntityIdFromExistingEntities', value._id).then(resp => {
                if (resp) onChangeEntityId()
            })
            showDropDownOnSelectEntity(false)
        }

        const onChangeEntityId = () => {
            state.entityId = incomingEntityId.value
        }

        watch(
            () => incomingEntityId.value,
            (currValue, prevValue) => {
                if (currValue !== prevValue) {
                    state.entityId = incomingEntityId.value
                }
            }
        )

        const addMoreItems = () => {
            state.invoice.items.push({
                name: '',
                product: '',
                price: 0,
                quantity: 1,
                instock: 0,
                discount: 0,
                discountType: 'percent',
                showDiscount: false,
                notes: '',
                showNote: false,
                total: 0,
                productDropDown: false,
                sku: '',
                id: '',
                type: ''
            })
        }

        const deleteProduct = index => {
            const { items } = state.invoice
            const productToDelete = items.splice(index, 1)
            state.invoice.items = items.filter(element => element !== productToDelete[0])
            state.invoice.items.splice(index, 1)
            store.dispatch('Inventory/removeProductId', index)
        }


        const deletePaymentPlan = index => {
            const { bankDetails } = state.invoice
            const paymentPlan = bankDetails.splice(index, 1)
            state.invoice.bankDetails = items.filter(element => element !== paymentPlan[0])
            store.dispatch('Inventory/removeProductId', index)
        }

        const addRemovePaymentPlan = bank => {
            // find the bank detail with the same bank name and account number
            const bankDetailExists = state.invoice.bankDetails.find(
                bankDetail => bankDetail.bankAccountNumber === bank.bankAccountNumber
            )
            if (bankDetailExists) {
                // remove the bank detail if it exists
                state.invoice.bankDetails = state.invoice.bankDetails.filter(
                    bankDetail => bankDetail.bankAccountNumber !== bank.bankAccountNumber
                )
            } else {
                // add the bank detail
                state.invoice.bankDetails.push(bank)
            }
        }

        // find by bankAccountNumber
        const findBankDetails = bankAccountNumber => {
            return state.invoice.bankDetails.findIndex(bankDetail => bankDetail.bankAccountNumber === bankAccountNumber)
        }

        const generatePaystackLink = () => {
            if (!checkCustomer()) return
            if (!checkItems()) return
            store.dispatch('Invoice/generatePaystackLink', state.invoice).then(data => {
                if (data.status) {
                    state.invoice.paystackSlug = data?.paystackSlug || ''
                    state.invoice.paystackLink = data?.paystackLink || ''
                }
            })
        }

        const removePaystackLink = () => {
            state.invoice.paystackSlug = ''
            state.invoice.paystackLink = ''
        }

        const toggleExtras = stateKey => {
            state.invoice[stateKey] = !state.invoice[stateKey]
            // check if the state is true or false
            if (stateKey === 'hasTax' && !state.invoice[stateKey]) {
                state.invoice.tax = 0
            } else if (stateKey === 'hasDiscount' && !state.invoice[stateKey]) {
                state.invoice.discount = 0
            } else if (stateKey === 'hasShipping' && !state.invoice[stateKey]) {
                state.invoice.shipping = 0
            } else if (stateKey === 'hasOthersCharges' && !state.invoice[stateKey]) {
                state.invoice.othersCharges = 0
            } else if (stateKey === 'hasCommission' && !state.invoice[stateKey]) {
                state.invoice.commission = 0
            }
        }

        // check due date
        const checkDueDate = () => {
            let isValidDate = false
            if (state.invoice.dateDue) {
                const dueDate = new Date(state.invoice.dateDue)
                const issueDate = new Date(state.invoice.dateIssued)
                // const today = new Date()
                if (dueDate < issueDate) {
                    store.dispatch('Alert/setAlert', {
                        message: `Due date cannot be less than today's date`,
                        status: false
                    })
                    isValidDate = false
                } else {
                    isValidDate = true
                }
            } else if (state.invoice.dateDue === '' || state.invoice.dateDue === undefined || state.invoice.dateDue === null) {
                store.dispatch('Alert/setAlert', {
                    message: `please enter a due date`,
                    status: false
                })
                isValidDate = false
            } else isValidDate = true
            // console.log(isValidDate, 'isValidDate')

            return isValidDate
        }

        const checkItems = () => {
            let isValidItems = false
            if (state.invoice.items.length > 0 && state.invoice.items[0].name) {
                isValidItems = true
            } else {
                store.dispatch('Alert/setAlert', {
                    message: `please add atleast one item`,
                    status: false
                })
                isValidItems = false
            }
            // console.log(isValidItems, 'isValidItems')
            return isValidItems
        }

        const checkPaymentPlan = () => {
            let isValidPaymentPlan = false
            if (
                (state.invoice.bankDetails.length > 0 && state.invoice.bankDetails[0].bankAccountNumber) ||
                state.invoice.paystackLink !== ''
            ) {
                isValidPaymentPlan = true
            } else {
                store.dispatch('Alert/setAlert', {
                    message: `please add atleast one payment plan`,
                    status: false
                })
                isValidPaymentPlan = false
            }

            return isValidPaymentPlan
        }
        const checkCustomer = () => {
            let isValidCustomer = false
            if (state.invoice.customer.name) {
                isValidCustomer = true
            } else {
                store.dispatch('Alert/setAlert', {
                    message: `please select a customer`,
                    status: false
                })
                isValidCustomer = false
            }
            // console.log(isValidCustomer, 'isValidCustomer')
            return isValidCustomer
        }

        // check status
        const checkStatus = () => {
            let isValidStatus = false
            // console.log(state.invoice.status, 'status')
            if (state.invoice.status.length > 0) {
                isValidStatus = true
            } else {
                store.dispatch('Alert/setAlert', {
                    message: `please select a status or save as draft`,
                    status: false
                })
                isValidStatus = false
            }
            return isValidStatus
        }

        const getTotalAfterDiscount = () => {
            const subtotal = parseFloat(state.invoice.subtotal)
            const discount = parseFloat(state.invoice.discount)
            if (state.invoice.discountType === 'fixed') {
                return subtotal - discount
            }

            return subtotal - (discount / 100) * subtotal
        }

        const processPartPayment = (invoice) => {
            invoice.status = 'draft'

            if (invoice.initial_deposit > 0) {
                invoice['due_date'] = new Date(invoice.dateDue)
                invoice['last_payment_date'] = new Date(invoice.date)
                invoice['deposit'] = invoice.initial_deposit
                invoice.glAccounts = [
                    {
                        glAccount: invoice.glAccounts[0].glAccount,
                        glAmount: invoice.initial_deposit
                    }
                ]
            }

            return invoice;
        }

        const validateItems = () => {
          let isAnyProductInvetoryZero = false
          for (const product of state.invoice.items) {
            if (product.quantity > product.instock) {
              isAnyProductInvetoryZero = true
              break;
            }
          }

          return isAnyProductInvetoryZero;
        }

        const onUpdate = status => {
            const isAnyProductInvetoryZero = validateItems()
            if (allowZeroStockLevel.value && isAnyProductInvetoryZero) {
                store.dispatch('Alert/setAlert', {
                    message: 'Item is not sufficient in inventory, please contact Admin or Inventory Manager to update stock',
                    success: false,
                })
                return false
            }
            state.invoice.tags = state.invoice.tags.map(tag => {
                return tag.text
            })
            const invoice = processPartPayment(state.invoice)
            invoice.totalAfterDiscount = getTotalAfterDiscount()
            if (status !== 'draft') {
                // check due date
                if (!checkDueDate()) return
                // check customer
                if (!checkCustomer()) return
                // check items
                if (!checkItems()) return
                // check payment plan
                if (route.fullPath.startsWith('/invoice') && !checkPaymentPlan()) return
                // check status
                // if(!checkStatus()) return

                invoice.status = route.fullPath.startsWith('/quotations') ? 'pending' : 'draft'
                const storeAction = route.fullPath.startsWith('/quotations')
                    ? 'Invoice/updateQuote'
                    : 'Invoice/updateInvoice'
                
                store.dispatch(storeAction, invoice).then(resp => {
                    if (resp) {
                        state.invoice = initialState.invoice
                        formRef.value.reset()
                    }
                })
            } else {
                if (!checkItems()) return

                state.invoice.status = status
                store.dispatch('Invoice/updateInvoice', invoice).then(resp => {
                    if (resp) {
                        state.invoice = initialState.invoice
                        formRef.value.reset()
                        // route to summary page
                        router.push({ name: 'InvoiceSummary' })
                    }
                })
            }
        }

        const toggleCustomer = () => {
            state.updateEntity = !state.updateEntity
            if (state.updateEntity) return
        }

        const toggleOrganaization = () => {
            state.updateOrganaization = !state.updateOrganaization
            if (state.updateOrganaization) return
        }

        const handleClickUpload = () => {
            // eslint-disable-next-line
            imageElRef?.value?.click()
        }

        const handleSelectImage = e => {
            if (e?.target?.files?.length) {
                const file = e.target.files[0]
                // eslint-disable-next-line prefer-destructuring
                state.imageFile = e.target.files[0]
                const reader = new FileReader()
                reader.onload = e => {
                    state.imageFile = file
                    state.imageUrl = e.target.result

                    if (reader.readyState === 2) {
                        state.image = reader.result.toString()
                    }

                    const formData = new FormData()
                    formData.append('image', state.image)
                    formData.append('imageFile', state.imageFile)
                    store.dispatch('Settings/updateBusinessLogo', formData)
                }
                reader.readAsDataURL(file)
            }
        }

        const handleDragImage = e => {
            if (e?.dataTransfer?.files?.length) {
                const file = e.dataTransfer.files[0]
                // eslint-disable-next-line prefer-destructuring
                state.imageFile = e.dataTransfer.files[0]
                const reader = new FileReader()
                reader.onload = e => {
                    state.imageFile = file
                    state.imageUrl = e.target.result

                    if (reader.readyState === 2) {
                        state.image = reader.result.toString()
                    }
                }
                reader.readAsDataURL(file)
            }
        }

        const setCheckValue = (value) => {
            state.invoice.reminder = value
        }

        const entityCreated = entity => {
            handleEntityClickFromSearch(entity)
        }

        watchEffect(() => {
            if (store.state.Invoice.getNewlyCreatedProduct) {
                const index = 0

                handleProdClickFromSearch(store.state.Invoice.getNewlyCreatedProduct, index)
            }
        })

        return {
            formatAmount,
            formatQuantity,
            state,
            toggleCreateEntity,
            productIds,
            handleProductSearchOnChange,
            showDropDownOnSelectProduct,
            handleIsRelative,
            toggleCreateProduct,
            productEntrySearchList,
            handleProdClickFromSearch,
            totalAmountValue,
            onChangeProductIds,
            handleEntitySearchOnChange,
            showDropDownOnSelectEntity,
            handleEntityClickFromSearch,
            EntitySearchedList,
            entityCreated,
            addMoreItems,
            deleteProduct,
            toggleExtras,
            deletePaymentPlan,
            addRemovePaymentPlan,
            onUpdate,
            isLoading,
            orgImage,
            orgInitials,
            findBankDetails,
            getCurrencyName,
            toggleCustomer,
            toggleOrganaization,
            handleDragImage,
            imageElRef,
            handleSelectImage,
            handleClickUpload,
            bankDetails,
            toggleUpdateBankAccountModal,
            calculateItemTotal,
            accountTaxDefault,
            bankAccounts,
            rolePermissions,
            role,
            generatePaystackLink,
            removePaystackLink,
            setCheckValue,
            toggleProductVariantsModal,
        }
    }
}
</script>

<style scoped>
.directional-up {
    width: 15px;
    height: 15px;
}
.sort1 {
    padding: 6px;
    cursor: pointer;
}
.sort1:hover {
    background-color: rgb(237, 240, 253);
    color: #132c8c;
    border-radius: 5px;
}
.org-img-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(215, 222, 250);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    font-size: 25px;
    color: rgb(19, 44, 140);
    margin-right: 5px;
}

.previewandsend {
    display: none;
}

@media screen and (max-width: 767px) {
    .discountInput {
        width: 70%;
    }
    .optionInput {
        width: 19%;
    }
    .previewandsend {
        display: block;
    }
}
</style>
