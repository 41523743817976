<template>
    <div v-if="bank?.bankAccountNumber" :type="isDefault" class="flex justify-between items-center box-border-bottom pt2 pb2">
        <div>{{ bank.bankName }}</div>
        <a v-if="state.active" @click.prevent="findBankDetails(bank)" style="color:red; cursor: pointer">Remove</a>
        <a v-else @click.prevent="findBankDetails(bank)" style="color:#132C8C; cursor: pointer">Insert</a>
    </div>
</template>
<script>
import { onMounted, reactive  } from 'vue'
export default {
    name: 'BankDetail',
    props: {
        bank: Object,
        isDefault: String
    },

    setup(props, { emit }) {
        const state = reactive({ active: false })
        
        const findBankDetails = bankAccountNumber => {
            state.active = !state.active
            emit('update-bank-details', bankAccountNumber)
        }

        onMounted(() => {
            state.active = props.isDefault
        })

        return {
            state,
            findBankDetails
        }
    }
}
</script>
